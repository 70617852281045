<template>
  <form @submit.prevent="upload" class="bg-gray-200 rounded p-2" enctype="multipart/form-data">
    <div class="flex items-center justify-between">
      <input
        type="file"
        multiple
        :name="uploadFieldName"
        :disabled="isSaving"
        @change="
          filesChange($event.target.name, $event.target.files);
          fileCount = $event.target.files.length;
        "
        accept="image/*"
        class="input-file btn-action"
      />
      <!-- <button type="submit" class="btn btn-action mt-2 rounded">Submit</button> -->
    </div>
    <p v-if="isInitial" class="text-sm text-gray-700">Drag your file(s) here to begin<br /></p>
    <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
  </form>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  data: function () {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: 0,
      uploadFieldName: 'photos',
      fileCount: null,
    };
  },
  props: {},
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  watch: {},
  methods: {
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // save it
      this.save(formData);
    },

    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      this.upload(formData);
    },

    upload: async function (formData) {
      try {
        let response = await this.$store.dispatch('upload', formData);
        this.uploadedFiles = [].concat(response);
        this.currentStatus = STATUS_SUCCESS;
      } catch (error) {
        console.log(error);
        this.currentStatus = STATUS_FAILED;
        this.$store.commit('setError', {
          message: error.response,
          color: 'red',
        });
        return;
      }
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
