<template>
  <div class="pb-4">
    <div class="grid grid-cols-2 bg-white my-8">
      <div class="p-8 min-h-1/2-screen">
        <Uploader class="mb-4 mt-2" />
        <div class="h-128 overflow-y-auto bg-gray-200 rounded">
          <div class="flex m-2 items-center">
            <label for="photoSearch" class="">Search</label>
            <input type="text" class="styled mx-2" id="photoSearch" v-model="photoSearch" />
          </div>

          <div v-if="loadingPhotos">
            <Loading />
          </div>
          <div class="flex flex-wrap" v-else>
            <label
              v-for="photo in photos"
              :key="photo"
              :for="photo"
              class="w-32 h-40 rounded shadow m-2 overflow-hidden cursor-pointer bg-white"
              @click="selectPhoto(photo)"
            >
              <div class="relative">
                <div class="m-auto trash-can-wrapper absolute right-0 top-0">
                  <button
                    @click="deletePhoto(photo)"
                    class="cursor-pointer hover:bg-red-200 py-1 px-2"
                  >
                    <font-awesome-icon icon="times" />
                  </button>
                </div>
                <img v-lazy="photo" class="w-32 h-32" alt="" />
                <input type="radio" :id="photo" name="photo" class="absolute left-0 bottom-0 m-1" />
              </div>
              <div class="text-xs text-center" for="">{{ getImageName(photo) }}</div>
            </label>
          </div>
        </div>
        <div class="justify-between flex items-center my-2">
          <button @click="fetchPhotos()" class="btn-action rounded">Get Photos</button>
          <button @click="beginWatermark()" class="btn-action rounded">Water mark</button>

          <button @click="clearPhotos()">Clear Photos</button>
        </div>
        <div class="text-center">
          <div class="relative overflow-hidden" ref="imageWrapper">
            <img :src="modalImage" ref="image" class="" alt="" />
            <div class="absolute top-2 left-0" style="opacity: 33%">
              <img src="/images/icons/Logo-primary.png" ref="imageLogo" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- Variants -->
      <div class="p-8">
        <div class="flex justify-between">
          <button class="btn-action rounded my-4" @click="bind">
            <font-awesome-icon icon="check" />
            Bind
          </button>
          <button class="btn-action rounded my-4" @click="bindAll">
            <font-awesome-icon icon="check" />
            Bind All
          </button>
        </div>
        <div class="h-1/2-screen overflow-y-auto bg-gray-200 mt-4">
          <div class="flex m-2 items-center">
            <label for="search" class="">Search</label>
            <input type="text" class="styled mx-2" id="search" v-model="search" />
            <div class="text-sm text-gray-500">(Only Variants without a picture)</div>
          </div>
          <div
            v-for="(variant, index) in variants"
            :key="variant._id"
            class="flex cursor-pointer py-2"
            :class="index % 2 ? null : 'bg-white'"
            @click="selectVariant(variant)"
          >
            <div class="mx-2"><input type="radio" :id="variant._id" name="variantVal" /></div>
            <label class="mx-2 cursor-pointer" :for="variant._id">{{ variant.productNum }}</label>
          </div>
        </div>
        <div class="justify-between flex">
          <button @click="fetchData()" class="btn-action rounded my-2">Get Variants</button>
        </div>
      </div>
    </div>
    <!-- Bindings to save -->
    <div class="p-8 flex flex-wrap relative bg-white w-full">
      <div
        class="rounded-lg shadow w-48 relative m-2"
        v-for="variant in boundVariants"
        :key="variant._id"
      >
        <button @click="unbind(variant)" class="cursor-pointer hover:bg-red-200 py-1 px-2">
          <font-awesome-icon icon="times" />
        </button>
        <div class="text-center w-48 h-48 m-2 overflow-hidden">
          <img class="mx-auto w-full" :src="variant.image" alt="" />
        </div>
        <div class="px-2 text-xs">
          <div>
            <div>
              Image:
              {{ getImageName(variant.image) }}
            </div>
          </div>
          <div>Product #: {{ variant.productNum }}</div>
          <button
            @click="saveVariant(variant)"
            class="bg-primary rounded-tl rounded-br-lg text-white px-2 py-1 absolute right-0 bottom-0"
          >
            <font-awesome-icon icon="file" />
          </button>
        </div>
      </div>
      <button class="absolute right-0 bottom-0 btn-action rounded" @click="saveAll">
        Save All
      </button>
    </div>
  </div>
</template>

<script>
import Uploader from './Uploader';
import Loading from '@/components/shared/Loading';
import html2canvas from 'html2canvas';

const axios = require('axios');

export default {
  data: function () {
    return {
      photo: null,
      variant: null,
      allVariants: null,
      search: null,

      loadingPhotos: false,
      photoSearch: null,
      allPhotos: null,
      modalImage: null,
      watermarkIndex: null,
      boundVariants: new Array(),
      formData: new FormData(),
    };
  },
  props: {},
  computed: {
    photos() {
      if (this.allPhotos) {
        const photos = this.allPhotos;
        // .filter((el) => {
        //   return el.includes('1000x1000');
        // });
        if (!this.photoSearch) {
          return photos;
        }

        return photos.filter((el) => {
          return el.toLowerCase().includes(this.photoSearch.toLowerCase());
        });
      }
      return new Array();
    },
    user() {
      return this.$store.state.user;
    },
    variants() {
      if (!this.allVariants) {
        return null;
      }
      if (!this.search) {
        return this.allVariants;
      }
      return this.allVariants.filter((el) => {
        if (el.productNum) return el.productNum.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    watermarkIndex() {
      if (this.watermarkIndex > 0) {
        this.beginWatermark();
      }
    },
  },
  methods: {
    getImageName(image) {
      const data = image.substring(21, 35);
      return data;
    },
    selectPhoto(path) {
      this.photo = path;
    },

    async fetchPhotos() {
      try {
        this.loadingPhotos = true;
        this.allPhotos = null;
        const response = await axios.get('/api/v1/photos/');
        this.allPhotos = response.data;
        this.loadingPhotos = false;
      } catch (error) {
        console.log(error);
      }
    },
    async clearPhotos() {
      try {
        await axios.patch('/api/v1/photos/');
      } catch (error) {
        console.log(error);
      }
    },
    selectVariant(variant) {
      this.variant = variant;
    },

    async fetchData() {
      try {
        const response = await axios.get('/api/v1/site/variants');
        this.allVariants = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    unbind(variant) {
      this.allPhotos.push(variant.image);
      variant.image = null;
      this.allVariants.push(variant);
      this.boundVariants = this.boundVariants.filter((el) => {
        return el._id != variant._id;
      });
    },
    bind() {
      if (!this.photo || !this.variant) {
        this.$store.commit('setError', {
          message: 'Photo or variant not selected.',
          color: 'red',
        });
        return;
      }
      this.variant.image = this.photo;
      if (this.photo.includes('1000x1000')) {
        this.variant.thumbnail = this.photo.replace('1000x1000', '300x300');
      }
      this.boundVariants.push(this.variant);
      this.allVariants = this.allVariants.filter((el) => {
        return el._id !== this.variant._id;
      });
      this.allPhotos = this.allPhotos.filter((el) => {
        return el !== this.photo;
      });

      this.photo = null;
      this.variant = null;
    },

    bindAll() {
      for (let i = 0; i < this.variants.length; i++) {
        const variant = this.variants[i];
        for (let j = 0; j < this.photos.length; j++) {
          const photo = this.photos[j];
          if (photo.includes(variant.productNum)) {
            this.variant = variant;
            this.photo = photo;
            this.bind();
            i--;
            j = this.photos.length;
          }
        }
      }
    },
    async saveVariant(variant) {
      try {
        await axios.patch('/api/v1/products/variant', variant);
        this.boundVariants = this.boundVariants.filter((el) => {
          return el._id != variant._id;
        });
      } catch (error) {
        console.log(error);
      }
    },
    beginWatermark() {
      this.addWatermark(this.allPhotos[0]);
    },
    addWatermark(photo) {
      this.modalImage = photo;

      setTimeout(() => {
        const image = this.$refs.image;
        this.$refs.imageWrapper.style.width = image.clientWidth + 'px';
        this.$refs.imageWrapper.style.height = image.clientHeight + 'px';
        this.$refs.imageLogo.style.height = image.clientHeight / 6 + 'px';
        setTimeout(() => {
          html2canvas(this.$refs.imageWrapper, { removeContainer: false })
            .then((canvas) => {
              document.querySelectorAll('.html2canvas-container').forEach((el) => {
                const iframe = el.contentWindow;
                if (el) {
                  el.src = 'about:blank';
                  iframe.document.write('');
                  iframe.document.clear();
                  iframe.close();
                  el.remove();
                }
              });
              canvas.toBlob(async (blob) => {
                this.formData.set(
                  'photo',
                  blob,
                  this.modalImage.substring(this.modalImage.lastIndexOf('/'))
                );

                await axios.patch('/api/v1/photos/watermark', this.formData);
                this.modalImage = null;
                this.allPhotos.shift();
                blob = null;
                this.beginWatermark();
              });
            })
            .catch((error) => {
              console.log('ERROR SAVING FESTIVAL IMAGE', error);
            });
        }, 100);
      }, 500);
    },
    async saveAll() {
      let count = 0;
      for (let i = 0; i < this.boundVariants.length; i++) {
        try {
          await axios.patch('/api/v1/products/variant', this.boundVariants[i]);
          this.boundVariants = this.boundVariants.filter((el) => {
            return el._id != this.boundVariants[i]._id;
          });
          i--;
          count++;
        } catch (error) {
          console.log(error);
        }
      }
      this.$store.commit('setError', {
        message: `Successfully saved ${count}`,
        color: 'green',
      });
    },
    async deletePhoto(path) {
      try {
        await axios.put(`/api/v1/photos/delete`, { path });
        this.allPhotos = this.allPhotos.filter((el) => {
          return el !== path;
        });
      } catch (error) {
        console.log(error);
      }

      // try {
      //   await axios.patch(`/api/v1/photos/delete`, { path });
      //   this.allPhotos = this.allPhotos.filter((el) => {
      //     return el !== path;
      //   });
      // } catch (error) {
      //   console.log(error);
      // }
    },
  },
  mounted() {},
  components: {
    Uploader,
    Loading,
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
